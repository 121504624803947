<template>
  <b-card
      class="inventoryorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="inventoryorderForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

        <b-col md="12">
          <b-card header="基本信息">
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="盘点编号"
                    label-for="inventory_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="inventory_no"
                      size="sm"
                      v-model="inventoryorder.inventory_no"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库方"
                    label-for="company_id"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="company_id"
                      :options="companys"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="ourCompanyName"
                      @input="setOurCompanyName($event)"
                      readonly=""
                      placeholder="请选择我方单位名称"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库"
                    label-for="warehouse_id"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="warehouse_id"
                      :options="warehouseArray"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="warehouseName"
                      @input="setWarehouseName($event)"
                      readonly=""
                      placeholder="请选择我方单位名称"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="盘点类型"
                    label-for="type"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="agreement_pay_method"
                      :options="getCodeOptions('inventory_type')"
                      :clearable="true"
                      v-model="inventoryType"
                      @input="changeSelect('type',$event)"
                      class="select-size-sm"
                      placeholder="请选择盘点类型"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="盘点开始时间"
                    label-for="start_time"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <flat-pickr
                      v-model="inventoryorder.start_time"
                      class="form-control"
                      :config="{enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="盘点结束时间"
                    label-for="end_time"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <flat-pickr
                      v-model="inventoryorder.end_time"
                      class="form-control"
                      :config="{enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  />
                </b-form-group>
              </b-col>
<!--              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="占用数据"
                    label-for="end_time"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-checkbox
                      v-model="isRTL"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                  />
                </b-form-group>
              </b-col>-->
            </b-row>
          </b-card>
        </b-col>

       <!-- <b-col md="12">
          <div class="inner-card-title">明细列表</div>
        </b-col>

        <b-col md="12">
          <inventory-order-detail-list
              :inventory_id="inventoryorder.inventory_id" v-if="itemListShowFlag" :isRTL="isRTL" ref="itemList" v-on:table="fromChildren">
          </inventory-order-detail-list>
        </b-col>-->

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->

    <!--  loading  -->
    <div v-show="loadingModal"><xy-loading ></xy-loading>  </div>



  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import inventoryorderStore from './inventoryorderStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import InventoryOrderDetailList from "@/views/apps/inventoryorderdetail/InventoryOrderDetailList";
import inventoryorderdetailStore from "@/views/apps/inventoryorderdetail/inventoryorderdetailStore";
import flatPickr from 'vue-flatpickr-component'
import XyLoading from "@/views/components/xy/XyLoading";

export default {
  components: {
    XyLoading,
    InventoryOrderDetailList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    flatPickr,
  },
  data() {
    return {
      id: ref(0),
      inventoryorder: ref({}),
      companys: [],
      warehouseArray: [],
      warehouseName: "",
      ourCompanyName: "",
      //itemListShowFlag: false,
      inventoryType:"",
      inventoryOrderDetail:[],
      isRTL:true,
      loadingModal:false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorder')) store.registerModule('inventoryorder', inventoryorderStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('inventoryorderdetail')) store.registerModule('inventoryorderdetail', inventoryorderdetailStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorder')) store.unregisterModule('inventoryorder')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('inventoryorderdetail')) store.unregisterModule('inventoryorderdetail')
    })

    const edit = function () {
      store.dispatch('inventoryorder/edit', {id: this.id}).then(res => {
        this.inventoryorder = res.data.data
        this.inventoryId = this.inventoryorder.inventory_id
        //this.itemListShowFlag = true
        this.ourCompanyName = getCodeLabel("company",this.inventoryorder.company_id)
        this.warehouseName = getCodeLabel("warehouse",this.inventoryorder.warehouse_id)
        this.inventoryType = getCode('inventory_type', this.inventoryorder.type)
        this.inventoryorder.end_time = toTime(this.inventoryorder.end_time)
        this.inventoryorder.start_time = toTime(this.inventoryorder.start_time)
        searchCompany(this.companys)
      })
    }

    const view = function () {
      store.dispatch('inventoryorder/view', {id: this.id}).then(res => {
        this.inventoryorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      this.inventoryorder.end_time = second(this.inventoryorder.end_time)
      this.inventoryorder.start_time = second(this.inventoryorder.start_time)
      if (this.inventoryorder.company_id === undefined || this.inventoryorder.company_id === "") {
        toast.error("请选择 仓库方!")
        return false
      }
      if (this.inventoryorder.warehouse_id === undefined || this.inventoryorder.warehouse_id === "") {
        toast.error("请选择 仓库!")
        return false
      }
      if (this.inventoryorder.type === undefined || this.inventoryorder.type === "") {
        toast.error("请选择 盘点类型!")
        return false
      }
      if (this.inventoryorder.start_time === undefined || this.inventoryorder.start_time === "") {
        toast.error("请填写 盘点开始时间!")
        return false
      }
      if (this.inventoryorder.end_time === undefined || this.inventoryorder.end_time === "") {
        toast.error("请填写 盘点结束时间!")
        return false
      }

      if (this.inventoryOrderDetail.length>0){
        store.dispatch('inventoryorderdetail/saveByArray', {detail:JSON.stringify(this.inventoryOrderDetail)}).then(res => {
          toast.success('明细数据已保存!')
        })
      }
      if (this.inventoryorder.status==1){
        store.dispatch('inventoryorder/save', this.inventoryorder).then(res => {
          if(res.data.code == 0){
            toast.success('数据已保存!')
            this.setLockQty(res.data.data)
          }else{
            toast.error(res.data.data)
          }
          this.$router.push({name: 'apps-inventoryorder-list'});
        })
      }else {
        toast.error("当前状态无法修改！")
      }


    }

    const searchCompany = function (params) {
      store.dispatch('company/getWareHouse').then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          params.push({"label": data[i].company_name, "code": data[i].company_id})
        }
      })
    }


    const setOurCompanyName = function (event) {
      this.inventoryorder.warehouse_id = ""
      this.warehouseName = ""
      if (event != undefined) {
        this.inventoryorder.company_id = event.code
        this.warehouseArray.length = 0
        store.dispatch('warehouse/search', {company_id: this.inventoryorder.company_id}).then(res => {
          const data = res.data.data.list
          for (let i = 0; i < data.length; i++) {
            this.warehouseArray.push({"label": data[i].warehouse_name, "code": data[i].warehouse_id})
          }
        })
      }

    }

    const setWarehouseName = function (event) {
      this.loadingModal=true
      if (event!=undefined){
        this.inventoryorder.warehouse_id = event.code
        store.dispatch('inventoryorderdetail/addInventoryOrderDetail', {
          warehouse_id: this.inventoryorder.warehouse_id,
          inventoryid: this.inventoryorder.inventory_id,
          order_by: "product_id",
        }).then(res => {
          this.loadingModal=false
          if(res.data.code == 0){
            toast.success("盘点明细已生成,请继续操作!")
          }else{
            toast.error(res.data.data)
          }
        })
      }

    }

    const changeSelect = function (key, event) {
      this.inventoryorder[key] = event.value
    }

    const fromChildren = function (val) {
      this.inventoryOrderDetail = val
    }

    const setLockQty = function (params) {
      this.loadingModal = true
      store.dispatch('inventoryorder/setLockQty', {inventory_id:params.inventory_id,warehouse_id:params.warehouse_id}).then(res => {
        this.loadingModal = false
        if (res.data.code === 0) {
          toast.success("更新成功")
        } else {
          toast.error(res.data.data)
        }
      })
    }


    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      second,
      //
      setOurCompanyName,
      searchCompany,
      setWarehouseName,
      changeSelect,
      fromChildren,
      setLockQty

    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
