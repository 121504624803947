import axios from '@axios'
import qs from 'qs'
import store from "@/store";

export default {
  namespaced: true,
  state: {
    condition: {
      warehousename:undefined,
      productName:undefined,
      //statusLabel:"临时抽盘",
      statusLabel:undefined,
      statusId: undefined ,
    }
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    resetCondition(state){
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/inventoryorder/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/inventoryorder/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/inventoryorder/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/inventoryorder/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/inventoryorder/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/inventoryorder/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
            url: '/api/inventoryorder/createExcel',
            method:'get',
            params: params,
            responseType: 'blob',
          }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createExcelData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
            url: '/api/inventoryorder/createExcelData',
            method:'get',
            params: params,
            responseType: 'blob',
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/inventoryorder/exportExcel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setLockQty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/inventoryorder/setLockQty', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },



  },
}
